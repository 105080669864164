import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne:
      'As we enter the final quarter of 2022, business leaders must prepare themselves for new and continued supply challenges and disruption. To proactively avoid supply chain impacts, Raindrop offers a completely integrated platform, enabling you to gain complete supplier spend transparency, seek alternate supply channels, stay on top of your suppliers’ performance compliance, all in Raindrop’s intuitively and easy-to-use platform.',
    HeadingOne: 'How to handle supply chain disruptions',
    ParaTwo:
      'Considering the Supply Chain disruption of 2022, many companies still lack a comprehensive picture of the risks lurking deep inside complex multitier supply networks.',
    HeadingOnePointOne: 'Supply chain resilience & risk management',
    ParaThreeLeft:
      'Supply-base resilience is critical to address vulnerabilities in any globalized and complex supply chain. It is essential to have quality access to your necessary inventory of components in order to move product out the door, from raw materials all the way to finished projects, and ultimately logistics to your customer. Around 90% of respondents from the advanced electronic and high tech industry, and 67% from the healthcare industry, plan to',
    anchorParaThreeOne: 'revise inventory management strategy',
    ParaThreeBetweenOne:
      'including decoupling points and buffer sizes. To achieve this, organizations must diversify their supplier base and consider boosting in-region sourcing if possible, to secure reliable access to your necessary raw materials and/or services.  Raindrop’s comprehensive',
    anchorParaThreeTwo: 'supplier dashboard',
    ParaThreeRight:
      'provides real-time visibility to monitor supplier compliance, sustainability, and operational risks, which leads to a more predictive supplier management framework.',
    AnchorShiftOne:
      'https://www.mckinsey.com/~/media/mckinsey/business functions/operations/our insights/taking the pulse of shifting supply chains/taking-the-pulse-of-shifting-supply-chains.pdf?shouldIndex=false',
    AnchorShiftTwo: 'https://raindrop.com/solutions/SupplierManagement',
    ImagePathOne:
      'https://storage.googleapis.com/raindroppublic/website_data/Shifting%20Supply%20Chain%20internal%202.jpg',
    HeadingOnePointTwo: 'Supply chain planning & digitization priorities',
    ParaFour:
      'Companies can manage their supply chains only if they have a clear picture of each link and the associated risks of each provider. Raindrop provides digital dashboards for end-to-end supply chain visibility, to avoid supply chain problems caused by disruptions. Raindrop helps to streamline the Supplier Management function and provides an up-to-date database with all your suppliers with respect to the equality, spend, commodities purchased, contracts in place, next events, and more. This helps to provide your company a complete picture of supplier performance, risks, areas of improvement, and the needed transparency to mitigate future risk gaps.',
    HeadingOnePointThree: 'Talent shortage',
    ParaFiveBefor:
      '“An acute shortage of talent is holding organizations back in their efforts to accelerate digitization and implement advanced planning systems.” - ',
    ParaFiveAnchor: 'McKinsey',
    ParaFiveAfter: '',
    AnchorLink:
      'https://www.mckinsey.com/capabilities/operations/our-insights/taking-the-pulse-of-shifting-supply-chains?cid=other-eml-alt-mip-mck&hlkid=a0944d105c294d60afbfe9464957cc46&hctky=12751614&hdpid=35c918a6-2c40-4e70-a770-95be9574e26a',
    ParaSix:
      'Supply chain leaders are dealing with recent challenges like the Great Resignation (or the Great Renegotiation), quiet quitting (workers doing the bare minimum), and the "soft life" (people that frequently start, stop, and start a job again). Organizations report “limited” or “no” in-house digital supply chain talent. As companies invest in digital spend management solutions like Raindrop, to help uplevel their entire approach to external spend obligations, the resources required to support this will be what separates companies in the marketplace. The past two years have also seen a marked shift in companies’ approach to talent acquisition, and hence creating a significant need for digitization, and switching to Raindrop’s AI-powered spend management solutions.',
    HeadingTwo: 'Leverage Raindrop to navigate Supply Chain challenges',
    ParaSeven:
      'Generate value from your supply chain by addressing and eliminating the challenges you face. Raindrop provides easy access for suppliers where they can view RFx invitations, respond with their desire to participate, ask clarifying questions, and submit proposals – all in one easy-to-use and powerful interface. Raindrop provides in-app guidance which eliminates the need for in-depth training. Our template libraries are designed with industry best practices, addressing several functional areas, including quality, pricing, supplier diversity, risk, and more.',
  },
]
